/** @format */

import { UserContext } from 'stores/UserStore'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { WMSContext } from './WmsStore'
import assetsInspSettings from '../api/assetsInspSettings'
import report from '../api/reports'
import common from '../api/common'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { FILE_TYPES, INSPECTION_STAGES } from '../utils/enum'
import formatReport from '../utils/reportInfoformatter'
import insp from '../api/inspection'
import usageRights from '../api/usageRights'
import modifprod from '../api/modifprod'
import batch from '../api/batch'
import moment from 'moment'

export const PrepareInspectionContext = React.createContext()

export const PrepareInspectionProvider = ({ children }) => {
	const { t } = useTranslation()
	const { token, user } = useContext(UserContext)
	const {
		warehouse,
		getLevelsData,
		getEnduserList,
		getMaterialsDescpritions,
		getLastLevelsList,
		getOrderList,
		getContentByOwnership,
		lastLevelsList
	} = useContext(WMSContext)
	const [loadingInspInfo, setLoadingInspInfo] = useState(false)
	const [loadingInpections, setLoadingInspections] = useState(false)
	const [loadingReports, setLoadingReports] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [openUpdateModal, setOpenUpdateModal] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)
	const [deleteWarning, setDeleteWarning] = useState(false)
	const [fetchingFirstPage, setFetchingFirstPage] = useState(false)
	const [fetchingLastPage, setFetchingLastPage] = useState(false)
	const [canPreviousPage, setCanPreviousPage] = useState(false)
	const [canNextPage, setCanNextPage] = useState(false)
	const [openUpdateFileModal, setopenUpdateFileModal] = useState(false)
	const [isMatRequisition, setIsMatRequisition] = useState(false)
	const [isArrival, setIsArrival] = useState(false)
	const [isReturn, setIsReturn] = useState(false)
	const [downloadingFile, setDownloadingFile] = useState(false)
	const [inspections, setInspections] = useState([])
	const [reports, setReports] = useState([])
	const [salesOrderList, setSalesOrderList] = useState([])
	const [filteredSalesItems, setFilteredSalesItems] = useState([])
	const [uploadedFile, setUploadedFile] = useState([])
	const [uploadList, setUploadList] = useState(false)
	const [selectedInsp, setSelectedInsp] = useState(null)
	const [selectedReport, setSelectedReport] = useState(null)
	const [formattedReport, setFormattedReport] = useState(null)
	const [endUsers, setEndUsers] = useState(null)
	const [ownerships, setOwnerships] = useState(null)
	const [materials, setMaterials] = useState(null)
	const [racks, setRacks] = useState(null)
	const [enduser, setEnduser] = useState(null)
	const [ownership, setOwnership] = useState(null)
	const [material, setMaterial] = useState(null)
	const [content, setContent] = useState(null)
	const [cOrder, setCOrder] = useState(null)
	const [cItem, setCItem] = useState(null)
	const [sOrder, setSOrder] = useState(null)
	const [sItem, setSItem] = useState(null)
	const [rack, setRack] = useState(null)
	const [quantity, setQuantity] = useState('')
	const [sourceMaterial, setSourceMaterial] = useState(null)
	const [targetMaterial, setTargetMaterial] = useState(null)
	const [file, setFile] = useState(null)
	const [inspName, setInspName] = useState(null)
	const [qntAvailable, setQntAvailable] = useState(0)
	const [reportLinks, setReportLinks] = useState(null)
	const [totalItems, setTotalItems] = useState(null)
	const [totalPages, setTotalPages] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [contentFilters, setContentFilters] = useState([])
	const [materialDescription, setMaterialDescription] = useState([])
	const [ownershipList, setOwnershipList] = useState([])
	const [sourceType, setSourceType] = useState(null)
	const errorGettingInspections = t('wms:ErrorGettingInspections')

	const getMaterialsByPipes = (rawData, validList) =>
		new Promise((resolve, reject) => {
			setLoadingInspInfo(true)
			Promise.allSettled(
				validList.map(async list => {
					return await common.getPipeData(warehouse.id, list.pipe_valid, null, token)
				})
			)
				.then(listArray => {
					let preList = []
					listArray.forEach(
						(list, index) => list.status === 'fulfilled' && list.value && (preList[index] = list.value)
					)
					const filteredMaterials = []
					preList.forEach(list => {
						filteredMaterials.push(
							...materials
								.filter(material => material.erpreference === list.pipedata.catid)
								.map(mat => ({
									mvid: mat.mvid,
									formatted: mat.formatted,
									valid: list.pipedata.pipevalid,
									contentid:
										validList.find(v => v.pipe_valid === list.pipedata.pipevalid) &&
										validList.find(v => v.pipe_valid === list.pipedata.pipevalid).content
								}))
						)
					})
					resolve(filteredMaterials)
					const formattedList = formatReport(
						rawData,
						selectedInsp.stage,
						selectedInsp.is_filter_on_content,
						filteredMaterials
					)
					setFormattedReport({
						summary: formattedList.inspSummary,
						info: formattedList.formattedInfo,
						content: formattedList.content,
						periodic: formattedList.periodicinfo
					})
				})
				.catch(e => {
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMaterialsByPipe')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
				.finally(() => {
					setLoadingInspInfo(false)
				})
		})
	const getDetailedReport = reportSelected =>
		new Promise((resolve, reject) => {
			const isModifiedProduct = selectedInsp.is_modified_product
			const isRigReturn = selectedInsp.stage === INSPECTION_STAGES.code.RETURN
			let formattedInfo
			const validList = []
			reportSelected != null &&
				report
					.getInspectionReportDetails(warehouse.id, reportSelected.id, reportSelected.type, token)
					.then(response => {
						resolve(response)
						isRigReturn &&
							!isModifiedProduct &&
							response.contents.forEach(content => {
								validList.push(...content.items)
							})
						validList.length
							? getMaterialsByPipes(response, validList)
							: (formattedInfo = formatReport(
									response,
									selectedInsp.stage,
									selectedInsp.is_filter_on_content,
									[]
							  ))
						formattedInfo !== undefined &&
							setFormattedReport({
								summary: formattedInfo.inspSummary,
								info: formattedInfo.inspInfo,
								content: formattedInfo.content,
								periodic: formattedInfo.periodicinfo
							})
					})
					.catch(e => {
						console.error(e)
						toast.error(`${t('wms:ErrorGettingReportDetails')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const getInspections = () =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				console.error(t(`wms:WarehouseNotDefined`))
			} else {
				assetsInspSettings
					.getInspections(warehouse.id, token)
					.then(response => {
						resolve()
						setInspections(
							_.filter(response, res => res.enabled === true && res.stage !== INSPECTION_STAGES.code.PERIODICAL)
						)
					})
					.catch(e => {
						console.error(e)
						reject(e)
						toast.error(`${errorGettingInspections} [ ${e.status} ]: ${e.data}`)
					})
			}
		})

	const getReports = inspName =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			report
				.getOpenReports(warehouse.id, inspName, token)
				.then(response => {
					setReports(response.items.sort((a, b) => a.id - b.id))
					setReportLinks(response.links)
					setTotalItems(response.total_items)
					setTotalPages(response.total_pages)
					setCurrentPage(response.current_page)
					if (response.items.length === 0) {
						setLoadingReports(false)
					}
					resolve()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${errorGettingInspections} [ ${e.status} ]: ${e.data}`)
					setLoadingReports(false)
					setReports(null)
				})
				.finally(() => {
					setMaterialDescription([])
					setLoadingReports(false)
				})
		})

	const getMoreReports = (page, numbersPerPage) =>
		new Promise((resolve, reject) => {
			setLoadingReports(true)
			report
				.getMoreOpenReports(warehouse.id, inspName, numbersPerPage, page, token)
				.then(response => {
					setReports(response.items.sort((a, b) => a.id - b.id))
					setReportLinks(response.links)
					setTotalItems(response.total_items)
					setTotalPages(response.total_pages)
					resolve()
					setFetchingFirstPage(false)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${errorGettingInspections} [ ${e.status} ]: ${e.data}`)
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
				.finally(() => {
					setFetchingFirstPage(false)
					setFetchingLastPage(false)
					setLoadingReports(false)
				})
		})

	const _getOwnershipByEnduser = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			usageRights
				.getOwnershipsListByEndUser(wid, enduser, token)
				.then(response => {
					const ownerDesc = response.ownershipdescriptions
					const levelList = ownerDesc.map(res => ({
						id: res.id,
						label: res.namepath.join('..')
					}))
					const ownerships = _.uniqBy(levelList, 'id')
					resolve(ownerships)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingOwnershipListByEU')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getOwnershipByEnduser = useCallback(
		enduser => _getOwnershipByEnduser(warehouse.id, enduser, token, t),
		[warehouse, token, t]
	)

	const _getMaterialsByEU = (wid, enduser, token, t) =>
		new Promise((resolve, reject) => {
			insp
				.getMaterialsByEU(wid, enduser, token)
				.then(response => {
					resolve(response.map(mat => ({ id: mat.id, formatted: mat.desc })))
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorGettingMatListEndUser')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	const getMaterialsByEU = useCallback(
		enduser => _getMaterialsByEU(warehouse.id, enduser, token, t),
		[warehouse, token, t]
	)

	const _getOrdersList = (enduserid, material, isCustomer, isSales) =>
		new Promise((resolve, reject) => {
			enduserid &&
				material &&
				getOrderList(enduserid, material, isCustomer, isSales)
					.then(list => {
						if (isCustomer) {
							let poNumbers = []
							let poItems = []
							_.forEach(list, (res, index) => {
								let n = res.number
								if (!poItems[n]) {
									poNumbers.push({ value: index, label: res.number })
									poItems[n] = []
								}
								poItems[n].push({ value: index, label: res.item })
							})
							resolve([poNumbers, poItems])
						} else {
							let salesNumbers = []
							let salesItems = []
							_.forEach(list, (res, i) => {
								let n = res.number
								if (!salesItems[n]) {
									salesNumbers.push({ value: i, label: n })
									salesItems[n] = []
								}
								salesItems[n].push({ value: i, label: res.item })
							})
							const salesOrderAndItem = [salesNumbers, salesItems]
							resolve(salesOrderAndItem)
						}
					})
					.catch(e => {
						console.error(e)
						reject(e)
					})
		})

	const getOrdersList = useCallback(
		(enduser, material, isCustomer, isSales) => _getOrdersList(enduser, material, isCustomer, isSales),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const getTargetMaterialList = enduser =>
		new Promise((resolve, reject) => {
			modifprod
				.getTargetMaterials(warehouse.id, enduser, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getSourceMaterialList = enduser =>
		new Promise((resolve, reject) => {
			batch
				.getMaterials(warehouse.id, enduser, token)
				.then(response => {
					resolve(response)
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorGettingMaterialDsc')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const getContentByOwner = ownership =>
		new Promise((resolve, reject) => {
			ownership &&
				getContentByOwnership(ownership)
					.then(response => {
						resolve(response)
					})
					.catch(e => {
						reject(e)
						console.error(e)
					})
		})

	const getAvailableQty = (
		ownership,
		enduser,
		mid,
		order,
		item,
		level,
		isSales,
		isCustomers,
		isAll,
		isModifiedProductInspection
	) =>
		new Promise((resolve, reject) => {
			if (!(warehouse && warehouse.id)) {
				reject(new Error(t('wms:WarehouseNotDefined')))
			}
			enduser &&
				insp
					.getAvailableQnt(
						warehouse.id,
						ownership,
						enduser,
						mid,
						order,
						item,
						level,
						isSales,
						isCustomers,
						isAll,
						isModifiedProductInspection,
						token
					)
					.then(itemQnty => {
						resolve(itemQnty)
					})
					.catch(e => {
						toast.error(`${t('wms:ErrorGettingItemsQuantity')} [ ${e.status} ]: ${e.data}`)
						reject(e)
					})
		})

	const openFile = () =>
		new Promise((resolve, reject) => {
			setDownloadingFile(true)
			selectedReport &&
				report
					.openFile(warehouse.id, selectedReport.type, selectedReport.id, token)
					.then(response => {
						let stage =
							selectedInsp.stage === INSPECTION_STAGES.code.ARRIVAL
								? 'Arrival Inspection File'
								: selectedInsp.stage === INSPECTION_STAGES.code.MATERIAL_REQUISITION
								? 'Material Requisition Inspection File'
								: 'Return Inspection File'
						let fileName = `${stage} (${moment(new Date()).format('M-DD-YYYY')}).${FILE_TYPES(response.type)}`
						const link = document.createElement('a')
						let fileToBeDownloaded = new Blob([response], {
							type: response.type
						})
						let fileURL = window.URL.createObjectURL(fileToBeDownloaded)
						link.href = fileURL
						link.target = '_blank'
						link.download = fileName
						link.click()
						resolve(true)
					})
					.catch(e => {
						setDownloadingFile(false)
						reject(e)
						console.error(e)
						toast.error(`${t('wms:ErrorDownloadingFile')} [ ${e.status} ]: ${e.data}`)
					})
					.finally(() => setDownloadingFile(false))
		})

	const deleteInspList = () =>
		new Promise((resolve, reject) => {
			insp
				.deleteInspection(warehouse.id, selectedReport.type, selectedReport.id, token)
				.then(() => {
					resolve()
					toast.success(t('wms:SuccessfullyDeletedInspection'))
					setDeleteWarning(false)
					getReports(selectedInsp.name)
				})
				.catch(e => {
					console.error(e)
					reject(e)
					toast.error(`${t('wms:ErrorDeletingInspection')} [ ${e.status} ]: ${e.data}`)
				})
		})

	const reopen = content =>
		new Promise((resolve, reject) => {
			let payload = {
				content_id: content.id
			}
			insp
				.reopenInspection(warehouse.id, payload, token)
				.then(() => {
					resolve(true)
					setUploadList(true)
					toast.success(t('wms:SuccessfullyReopenContent'))
					setReports([])
					getReports()
				})
				.catch(e => {
					reject(e)
					console.error(e)
					toast.error(`${t('wms:ErrorReopenContent')} [ ${e.status} ]: ${e.status}`)
				})
		})

	const uploadFile = (file, stage, type) =>
		new Promise((resolve, reject) => {
			if (window.FileReader) {
				const reader = new FileReader()
				reader.onload = event => {
					let payload = {
						stage: stage,
						file: event.target.result ? event.target.result : null,
						type_file: type
					}
					payload.file !== null &&
						insp
							.uploadFile(warehouse.id, payload, token)
							.then(response => {
								resolve(response)
							})
							.catch(e => {
								toast.error(
									`${t('wms:UploadFailure')} [ ${e.status} ]: ${t('wms:MaximumSizeAllowed')}. ${
										e.statusText ? e.statusText : e.data
									}`
								)
								reject(e)
							})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	const newInspection = (data, file, stage, id) =>
		new Promise((resolve, reject) => {
			const inspection = inspections.find(insp => insp.id === id)
			const isRigReturn = stage === INSPECTION_STAGES.code.RETURN && inspection && !inspection.is_modified_product
			let payload
			if (isRigReturn) {
				payload = {
					filters: {
						enduser: enduser,
						target_ownership: ownership
					},
					summary: data.summary,
					number_of_parts: data.number_of_parts,
					type: id,
					plan_responsible: `${user.firstName} ${user.lastName}`,
					expected_date: data.expected_date,
					file: file ? file : null
				}
			} else {
				payload = {
					...data,
					type: id,
					plan_responsible: `${user.firstName} ${user.lastName}`,
					file: file ? file : null
				}
			}
			insp
				.newInspection(warehouse.id, payload, stage, sourceType === 'ALL_SOURCES' ? true : false, token)
				.then(() => {
					toast.success(t('wms:InspectionCreated'))
					resolve(true)
					setOpenModal(false)
					setEnduser(null)
					setOwnership(null)
					setMaterial(null)
					setSourceMaterial(null)
					setTargetMaterial(null)
					setRack(null)
					setFile(null)
				})
				.catch(e => {
					toast.error(`${t('wms:ErrorCreatingInspection')} [ ${e.status} ]: ${e.data}`)
					reject(e)
				})
		})

	Object.filter = (obj, predicate) =>
		Object.keys(obj)
			.filter(key => predicate(obj[key]))
			.reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})

	const updateInspection = (data, stage, reportID, reportType) =>
		new Promise((resolve, reject) => {
			const filtered = data && Object.filter(data.summary, sm => sm !== null)
			const isRigReturn = stage === INSPECTION_STAGES.code.RETURN && !selectedInsp.is_modified_product

			const handleContents = contents => {
				return contents
					.map(content => {
						return Object.keys(content).reduce((newObj, key) => {
							const value = content[key]
							if (value !== null) newObj[key] = value
							return newObj
						}, {})
					})
					.map(ct => ({
						...ct,
						filters: _.pickBy(ct.filters, value => {
							return !(value === null)
						})
					}))
			}
			const contents = handleContents(data.contents)

			const deletedContents = handleContents(data.deletedcontents)

			const handlePayload = () => {
				const payload = {
					summary: filtered,
					contents: contents,
					deletedcontents: deletedContents,
					plan_responsible: `${user.firstName} ${user.lastName}`,
					type: reportType,
					expected_date: data.expected_date,
					closure_date: data.closure_date
				}
				if (isRigReturn) {
					return {
						...payload,
						number_of_parts: data.number_of_parts,
						filters: data.filters,
						file: data.file,
						closure_date: data.closure_date
					}
				} else return payload
			}
			const resetData = () => {
				setOpenUpdateModal(false)
				setEnduser(null)
				setOwnership(null)
				setMaterial(null)
				setSourceMaterial(null)
				setTargetMaterial(null)
				setRack(null)
				setFile(null)
				setopenUpdateFileModal(false)
				setUploadedFile([])
			}
			handlePayload() &&
				stage &&
				insp
					.updateInspection(
						warehouse.id,
						handlePayload(),
						stage,
						reportID,
						sourceType === 'ALL_SOURCES' ? true : false,
						token
					)
					.then(response => {
						toast.success(t('wms:SuccessfullyUpdatedInspection'))
						resolve(response)
						resetData()
						getReports(inspName)
					})
					.catch(e => {
						reject(e)
						toast.error(`${t('wms:ErrorUpdatingInspection')} [${e.status}]: ${e.data}`)
						console.error(e)
					})
		})

	const updateInspectionFile = (file, stage, type) =>
		new Promise((resolve, reject) => {
			if (window.FileReader) {
				const reader = new FileReader()
				reader.onload = event => {
					let payload = {
						stage: stage,
						file: event.target.result ? event.target.result : null,
						type_file: type
					}
					payload.file !== null &&
						insp
							.uploadFile(warehouse.id, payload, token)
							.then(response => {
								resolve(response)
							})
							.catch(e => {
								reject(e)
								console.error(e)
								toast.error(`${t('wms:UpdateFailure')} [ ${e.status} ]: ${e.data}`)
							})
				}
				reader.onerror = () => {
					toast.error(t('wms:ErrorReadingFile'))
				}
				reader.readAsDataURL(file)
			} else reject('Filereader not found')
		})

	/*************************************************************
	 *                       USE EFFECT
	 **************************************************************/

	useEffect(() => {
		const hydrate = async () => {
			try {
				setLoadingInspections(true)
				await getInspections()
			} catch (e) {
				console.error(e)
				setLoadingInspections(false)
			} finally {
				setLoadingInspections(false)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (selectedReport && selectedInsp) {
			const hydrate = async () => {
				try {
					await getDetailedReport(selectedReport)
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedReport])

	useEffect(() => {
		getLevelsData()
			.then(levels => setOwnerships(levels))
			.catch(e => console.error(e))
		getEnduserList()
			.then(enduser => setEndUsers(enduser))
			.catch(e => console.error(e))
		getMaterialsDescpritions()
			.then(material => setMaterials(material))
			.catch(e => console.error(e))
		getLastLevelsList()
			.then(rack => setRacks(rack))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		setFormattedReport(null)
	}, [selectedInsp, warehouse, reports])

	useEffect(() => {
		const hydrate = async () => {
			try {
				inspName && (await getReports(inspName))
			} catch (e) {
				console.error(e)
			}
		}
		hydrate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspName])

	useEffect(() => {
		setReports([])
		setTotalPages(null)
	}, [selectedInsp, warehouse])

	useEffect(() => {
		uploadList &&
			selectedInsp &&
			getReports(selectedInsp.name)
				.then(list => setReports(list))
				.catch(e => console.error(e))
		setUploadList(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadList, selectedInsp])

	useEffect(() => {
		setSelectedReport(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openModal])

	return (
		<PrepareInspectionContext.Provider
			value={{
				inspections,
				setInspections,
				selectedInsp,
				setSelectedInsp,
				reports,
				setReports,
				loadingInpections,
				setLoadingInspections,
				getReports,
				loadingReports,
				setLoadingReports,
				selectedReport,
				setSelectedReport,
				formattedReport,
				setFormattedReport,
				endUsers,
				setEndUsers,
				ownerships,
				setOwnerships,
				materials,
				setMaterials,
				racks,
				setRacks,
				openFile,
				deleteInspList,
				reopen,
				getOwnershipByEnduser,
				getMaterialsByEU,
				getOrdersList,
				getTargetMaterialList,
				getSourceMaterialList,
				getContentByOwner,
				getAvailableQty,
				uploadFile,
				newInspection,
				enduser,
				setEnduser,
				ownership,
				setOwnership,
				material,
				setMaterial,
				content,
				setContent,
				cOrder,
				setCOrder,
				cItem,
				setCItem,
				sOrder,
				setSOrder,
				sItem,
				setSItem,
				rack,
				setRack,
				quantity,
				setQuantity,
				sourceMaterial,
				setSourceMaterial,
				targetMaterial,
				setTargetMaterial,
				file,
				setFile,
				qntAvailable,
				setQntAvailable,
				openModal,
				setOpenModal,
				isUpdate,
				setIsUpdate,
				openUpdateModal,
				setOpenUpdateModal,
				lastLevelsList,
				updateInspection,
				deleteWarning,
				setDeleteWarning,
				inspName,
				setInspName,
				reportLinks,
				setReportLinks,
				totalItems,
				setTotalItems,
				totalPages,
				setTotalPages,
				currentPage,
				setCurrentPage,
				getMoreReports,
				fetchingFirstPage,
				setFetchingFirstPage,
				canPreviousPage,
				setCanPreviousPage,
				canNextPage,
				setCanNextPage,
				fetchingLastPage,
				setFetchingLastPage,
				salesOrderList,
				setSalesOrderList,
				filteredSalesItems,
				setFilteredSalesItems,
				updateInspectionFile,
				openUpdateFileModal,
				setopenUpdateFileModal,
				uploadedFile,
				setUploadedFile,
				isMatRequisition,
				setIsMatRequisition,
				isArrival,
				setIsArrival,
				isReturn,
				setIsReturn,
				downloadingFile,
				setDownloadingFile,
				contentFilters,
				setContentFilters,
				materialDescription,
				setMaterialDescription,
				ownershipList,
				setOwnershipList,
				loadingInspInfo,
				setLoadingInspInfo,
				sourceType,
				setSourceType
			}}>
			{children}
		</PrepareInspectionContext.Provider>
	)
}
