/** @format */
/* eslint-disable react/prop-types */

import { PrepareInspectionContext } from 'apps/wms/stores/PrepareInspectionStore'
import { Input, Select } from 'components/form'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _, { toInteger } from 'lodash'
import { Button, FlexView, Icon, LoadingOverlay } from 'components/common'
import { INSPECTION_STAGES, SOURCE_TYPES_ENTRANCE } from 'apps/wms/utils/enum'
import { toast } from 'react-toastify'

const Filters = ({ inspectionType, inspections, newContent, materialList, selectMaterial, selectLevel, newData }) => {
	const { t } = useTranslation()
	const {
		ownerships,
		getOwnershipByEnduser,
		endUsers,
		getMaterialsByEU,
		getAvailableQty,
		getContentByOwner,
		getOrdersList,
		enduser,
		setEnduser,
		ownership,
		setOwnership,
		material,
		setMaterial,
		content,
		setContent,
		cOrder,
		setCOrder,
		cItem,
		setCItem,
		sOrder,
		setSOrder,
		sItem,
		setSItem,
		rack,
		setRack,
		quantity,
		setQuantity,
		sourceMaterial,
		setSourceMaterial,
		targetMaterial,
		setTargetMaterial,
		qntAvailable,
		setQntAvailable,
		salesOrderList,
		setSalesOrderList,
		filteredSalesItems,
		setFilteredSalesItems,
		isMatRequisition,
		isArrival,
		isReturn,
		selectedReport,
		contentFilters,
		setContentFilters,
		ownershipList,
		setOwnershipList,
		sourceType,
		setSourceType
	} = useContext(PrepareInspectionContext)

	const [enduserList, setEnduserList] = useState([])

	const [materials, setMaterials] = useState([])
	const [customerOrderList, setCustomerOrderList] = useState([])
	const [customerItemList, setCustomerItemList] = useState([])
	const [filteredPoItems, setFilteredPoItems] = useState([])
	const [salesItemList, setSalesItemList] = useState([])
	const [sourceMatList, setSourceMatList] = useState([])
	const [targetMatList, setTargetMatList] = useState([])
	const [shelfList, setShelfList] = useState([])
	const [contentData, setContentData] = useState({
		content: [],
		expected_date: ''
	})

	const [loading, setLoading] = useState(false)
	const [loadingQty, setLoadingQty] = useState(false)
	const [totalQntPipesFilteredInsp, setTotalQntPipesFilteredInsp] = useState(0)

	const materialStr = t('wms:Material')

	const checkRules = () => {
		let isDisabled = true
		if (selectedReport && isReturn && inspectionType.is_modified_product) {
			selectedReport.contents && selectedReport.contents.find(report => report.filters.enduser)
				? (isDisabled = true)
				: (isDisabled = false)
			return isDisabled
		} else if (inspectionType && !inspectionType.is_filter_on_content) {
			contentFilters &&
			(contentFilters.find(filter => filter.enduser) || contentFilters.find(filter => filter.material))
				? (isDisabled = true)
				: (isDisabled = false)
		} else isDisabled = false

		return isDisabled
	}

	const checkButtonRules = () => {
		return !isRigReturn()
	}

	const allNull = () => {
		let keys = Object.keys(contentData)
		let isEmpty = true
		_.forEach(keys, k => {
			return (isEmpty = _.some(contentData, n => n[k] === ''))
		})
		return enduser === null && ownership === null && isEmpty
	}

	const resetFields = () => {
		setEnduser(null)
		setOwnership(null)
		let summary = inspectionType?.config_summary?.filter(s => s.enabled === true)
		let keys = summary && _.map(summary, s => ({ [s.name.toLowerCase()]: '' }))
		let params = keys && Object.assign({}, ...keys)
		params
			? setContentData({
					...contentData,
					...params,
					content: []
			  })
			: setContentData({
					...contentData,
					name: '',
					content: []
			  })
	}

	const addContent = () => {
		if (isArrival) {
			switch (sourceType) {
				case 'CUSTOMER_ITEM':
					newContent({
						filters: {
							enduser: enduser,
							material: material,
							customer_order: cOrder,
							customer_item: cItem
						},
						quantity: quantity,
						execution_status: 0
					})
					setContentFilters([
						...contentFilters,
						{
							quantity: quantity,
							enduser: enduser,
							material: material,
							customer_order: cOrder,
							customer_item: cItem
						}
					])
					break
				case 'SALES_ITEM':
					newContent({
						filters: {
							enduser: enduser,
							material: material,
							sales_order: salesOrderList.find(s => s.label === sOrder).label,
							sales_item: filteredSalesItems.find(s => s.label === sItem).label
						},
						quantity: quantity,
						execution_status: 0
					})
					setContentFilters([
						...contentFilters,
						{
							quantity: quantity,
							enduser: enduser,
							material: material,
							sales_order: cOrder,
							sales_item: cItem
						}
					])
					break
				case 'ALL_SOURCES':
					newContent({
						filters: {
							enduser: enduser,
							material: material
						},
						quantity: quantity,
						execution_status: 0
					})
					setContentFilters([
						...contentFilters,
						{
							quantity: quantity,
							enduser: enduser,
							material: material
						}
					])
					break
				default:
					break
			}
			if (selectedReport?.filters?.customer_order) {
				newContent({
					filters: {
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						customer_order: selectedReport.filters.customer_order,
						customer_item: selectedReport.filters.customer_item
					},
					quantity: quantity,
					execution_status: 0
				})
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						customer_order: selectedReport.filters.customer_order,
						customer_item: selectedReport.filters.customer_item
					}
				])
			}

			if (selectedReport?.filters?.sales_order) {
				newContent({
					filters: {
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						sales_order: selectedReport.filters.sales_order,
						sales_item: selectedReport.filters.sales_item
					},
					quantity: quantity,
					execution_status: 0
				})
				setContentFilters([
					...contentFilters,
					{
						quantity: quantity,
						enduser: selectedReport.filters.enduser,
						material: selectedReport.filters.material,
						sales_order: selectedReport.filters.sales_order,
						sales_item: selectedReport.filters.sales_item
					}
				])
			}
			setQuantity(0)
		}
		if (isMatRequisition) {
			let rackName = _.find(shelfList, lv => lv.id === rack)
			newContent({
				filters: {
					source_ownership: ownership,
					enduser: enduser,
					material: material,
					level: rack,
					levelname: rackName?.fullname
				},
				quantity: toInteger(quantity),
				execution_status: 0
			})
			setContentFilters([
				...contentFilters,
				{
					source_ownership: ownership,
					enduser: enduser,
					material: material,
					level: rack,
					quantity: toInteger(quantity)
				}
			])
			setQuantity(0)
		}
		if (inspectionType.is_modified_product && isReturn) {
			newContent({
				filters: {
					enduser: enduser,
					material: targetMaterial,
					material_source: sourceMaterial,
					target_ownership: ownership
				},
				quantity: quantity,
				execution_status: 0
			})
			setContentFilters([
				...contentFilters,
				{
					enduser: enduser,
					material: targetMaterial,
					material_source: sourceMaterial,
					target_ownership: ownership,
					quantity: quantity
				}
			])
			setQuantity(0)
		}
	}

	/* this method is here to detect if we have all informations for the inspection to ask 
	number of pipes availables, this is slighly different from isFilterMissing 
	because target stuff is useless in this scenario*/
	const isMissingStuffToUpdateQuantities = () => {
		let isMissingStuffToAskForQuantities = true
		const isCustomer = sourceType === 'CUSTOMER_ITEM'
		const isSales = sourceType === 'SALES_ITEM'
		if (isMatRequisition) {
			isMissingStuffToAskForQuantities = enduser === null || material === null || ownership === null || rack === null
		} else if (isArrival) {
			isMissingStuffToAskForQuantities =
				(isCustomer && (cOrder === null || cItem === null)) ||
				(isSales && (sOrder === null || sItem === null)) ||
				enduser === null ||
				material === null
		} else if (isModifiedProductInspection()) {
			isMissingStuffToAskForQuantities = sourceMaterial === null || enduser === null
		}
		return isMissingStuffToAskForQuantities
	}

	const isAddPartDisabled = () => {
		let isAddPartDisabled = true
		const isCustomer = sourceType === 'CUSTOMER_ITEM'
		const isSales = sourceType === 'SALES_ITEM'
		if (isMatRequisition) {
			isAddPartDisabled =
				enduser === null || material === null || ownership === null || rack === null || quantity <= 0
		} else if (isArrival) {
			isAddPartDisabled =
				(isCustomer && (cOrder === null || cItem === null)) ||
				(isSales && (sOrder === null || sItem === null)) ||
				enduser === null ||
				material === null ||
				quantity <= 0
		} else if (isModifiedProductInspection()) {
			isAddPartDisabled = sourceMaterial === null || targetMaterial === null || enduser === null || quantity <= 0
		}
		return isAddPartDisabled
	}

	/* we suppose that the execution of this method is done after
	you check with isMissingStuffToAskForQuantities that there is no missing data
	*/
	const sumByProp = (data, prop) => {
		if (data.length === 0) {
			return 0
		}
		let filteredData = []
		if (isArrival) {
			if (cOrder && cItem) {
				filteredData =
					data.filter(
						val =>
							val.filters?.customer_order === cOrder &&
							val.filters?.customer_item === cItem &&
							val.filters?.material === material &&
							val.filters?.enduser === enduser
					) || []
			} else if (sOrder && sItem) {
				filteredData =
					data.filter(
						val =>
							val.filters?.sales_order === sOrder &&
							val.filters?.sales_item === sItem &&
							val.filters?.material === material &&
							val.filters?.enduser === enduser
					) || []
			} else {
				filteredData =
					data.filter(val => val.filters?.enduser === enduser && val.filters?.material === material) || []
			}
		} else if (isMatRequisition) {
			filteredData =
				data.filter(
					val =>
						val.filters?.enduser === enduser &&
						val.filters?.source_ownership === ownership &&
						val.filters?.material === material &&
						val.filters?.level === rack
				) || []
		} else if (isModifiedProductInspection()) {
			filteredData = data.filter(val => val.filters?.enduser === enduser && val.filters?.material === material) || []
		}
		if (filteredData.length !== 0) {
			return filteredData.reduce((acc, curr) => {
				return acc + curr[prop]
			}, 0)
		}
		return 0
	}

	useEffect(() => {
		/* this code permit to update the quantity of pipes available for the following filters*/
		if (totalQntPipesFilteredInsp && !isMissingStuffToUpdateQuantities()) {
			const quantityPipesMatchingSelectedFilters = sumByProp(newData?.contents, 'quantity')
			setQntAvailable(totalQntPipesFilteredInsp - quantityPipesMatchingSelectedFilters)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newData.contents])

	useEffect(() => {
		if (!inspectionType) return
		if (!inspectionType.config_filters.find(f => f.name === 'Enduser')) setEnduserList([])
		else setEnduserList(endUsers ? endUsers : [])
	}, [endUsers, inspectionType])

	const resetStates = () => {
		setOwnershipList([])
		setCustomerOrderList([])
		setCustomerItemList([])
		setMaterials([])
		materialList([])
	}

	const defineConstants = (unifiedMaterials, matList, owners, ownerships) => {
		materialList(unifiedMaterials)
		setSourceMatList(unifiedMaterials)
		setTargetMatList(unifiedMaterials)
		setMaterials(matList)
		setOwnershipList((owners && owners.length && owners) || ownerships)
	}

	useEffect(() => {
		if (ownership) {
			const hydrate = async () => {
				try {
					setLoading(true)
					const contents = await getContentByOwner(ownership, 0).finally(() => setLoading(false))
					setContent(contents)
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownership])

	useEffect(() => {
		if (material && enduser && sourceType) {
			const hydrate = async () => {
				try {
					setLoading(true)
					const isCustomer = sourceType === 'CUSTOMER_ITEM'
					const isSales = sourceType === 'SALES_ITEM'
					const list = await getOrdersList(enduser, material, isCustomer, isSales).finally(() => setLoading(false))
					if (isCustomer) {
						setCustomerOrderList(list[0] || [])
						setCustomerItemList(list[1] || [])
					} else if (isSales) {
						setSalesOrderList(list[0] || [])
						setSalesItemList(list[1] || [])
					}
				} catch (e) {
					console.error(e)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material, enduser, sourceType])

	useEffect(() => {
		if (!inspectionType) {
			resetStates()
		} else {
			const hydrate = async () => {
				try {
					setLoading(true)
					const owners = (enduser && (await getOwnershipByEnduser(enduser).finally(() => setLoading(false)))) || []
					const matList = (enduser && (await getMaterialsByEU(enduser).finally(() => setLoading(false)))) || []
					const unifiedMaterials = _.uniqBy(matList, 'id').sort((a, b) => a.id - b.id)
					defineConstants(unifiedMaterials, matList, owners, ownerships)
				} catch (e) {
					console.error(e)
				} finally {
					setLoading(false)
				}
			}
			hydrate()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enduser, inspectionType, inspections, ownerships, ownership])

	useEffect(() => {
		if (!inspectionType) {
			setFilteredPoItems([])
			setFilteredSalesItems([])
		} else if (isArrival && cOrder) {
			setCItem(null)
			setFilteredPoItems(customerItemList[cOrder])
		} else if (isArrival && sOrder) {
			setSItem(null)
			cOrder && setFilteredPoItems(customerItemList[cOrder])
			sOrder && setFilteredSalesItems(salesItemList[sOrder])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cOrder, customerItemList, inspectionType, inspections, sOrder, salesItemList])

	useEffect(() => {
		setEnduser(null)
		setOwnership(null)
		setMaterial(null)
		setContent(null)
		setCOrder(null)
		setCItem(null)
		setSOrder(null)
		setSItem(null)
		setRack(null)
		setSourceMaterial(null)
		setTargetMaterial(null)
		setSourceType(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionType])

	useEffect(() => {
		if (content?.length) {
			let materials = content
				.filter(item => item.material.enduserid === enduser)
				.map(ct => ({
					enduserid: ct.material.enduserid,
					id: ct.material.id,
					formatted: ct.material.formatted
				}))
				.sort((a, b) => a.id - b.id)
			let uniqMaterial = _.uniqBy(materials, 'id')
			materialList(uniqMaterial)
			setMaterials(uniqMaterial)
			let aux = content.map(item => ({
				id: item.level.id,
				fullname: item.level.fullname,
				creationavg: item.creationavg,
				itemcount: item.itemcount
			}))
			aux.sort((a, b) => a.fullname - b.fullname)
			let list = content
				.filter(item => item.material.id === material)
				.map(item => ({
					id: item.level.id,
					fullname: item.level.fullname,
					creationavg: item.creationavg,
					itemcount: item.itemcount
				}))
			setShelfList(list)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [material, content])

	useEffect(() => {
		const isCustomer = sourceType === 'CUSTOMER_ITEM'
		const isSales = sourceType === 'SALES_ITEM'
		const isAllSources = sourceType === 'ALL_SOURCES'
		setQuantity(0)
		setQntAvailable(0)
		if (!isMissingStuffToUpdateQuantities()) {
			setLoadingQty(true)
			getAvailableQty(
				isModifiedProductInspection() ? null : ownership,
				enduser,
				isModifiedProductInspection() ? sourceMaterial : material,
				cOrder || sOrder || null,
				cItem || sItem || null,
				rack,
				isSales,
				isCustomer,
				isAllSources,
				isModifiedProductInspection()
			)
				.then(quantity => {
					if (quantity === 0) toast.warn(t('wms:NoAvailableQuantity'))
					setQntAvailable(quantity - sumByProp(newData?.contents, 'quantity'))
					setTotalQntPipesFilteredInsp(quantity)
				})
				.catch(e => console.error(e))
				.finally(() => setLoadingQty(false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownership, cItem, cOrder, enduser, material, sItem, sOrder, sourceMaterial, sourceType, rack])

	const isRigReturn = () => {
		return (
			inspectionType && inspectionType.stage === INSPECTION_STAGES.code.RETURN && !inspectionType.is_modified_product
		)
	}

	const isModifiedProductInspection = () => {
		return (
			inspectionType && inspectionType.stage === INSPECTION_STAGES.code.RETURN && inspectionType.is_modified_product
		)
	}

	return (
		<FlexView width='100%'>
			<LoadingOverlay visible={loading} borderRadius='card' />
			<FlexView width='100%' margin='0' padding='0' position='relative'>
				{isArrival && (
					<Select
						label={t('wms:TypeOfSource')}
						placeholder={t('wms:TypeOfSource')}
						value={sourceType}
						options={SOURCE_TYPES_ENTRANCE.map(source => ({
							value: source.value,
							label: t(source.label)
						}))}
						onChange={value => {
							setSourceType(value)
						}}
						width='100%'
						inline={false}
						margin='0'
						disabled={checkRules()}
						data-cy='select-source-type'
					/>
				)}
				<Select
					label={t('wms:EndUser')}
					placeholder={t('wms:EndUser')}
					value={
						isReturn &&
						inspectionType &&
						!inspectionType.is_modified_product &&
						selectedReport &&
						selectedReport.filters &&
						selectedReport.filters.enduser
							? selectedReport.filters.enduser
							: enduser
					}
					options={enduserList.map(eu => ({ value: eu.id, label: eu.name }))}
					onChange={value => {
						setEnduser(value)
					}}
					width='100%'
					margin='8px 0 0 0'
					disabled={
						checkRules() ||
						(isReturn &&
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.enduser)
					}
					data-cy='select-enduser'
				/>
				{isMatRequisition && (
					<Select
						label={t('wms:SourceOwnership')}
						placeholder={t('wms:SourceOwnership')}
						value={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.source_ownership
								? selectedReport.filters.source_ownership
								: ownership
						}
						options={ownershipList.map(eu => ({ value: eu.id, label: eu.label }))}
						onChange={value => {
							setMaterial(null)
							selectLevel(null)
							setRack(null)
							setOwnership(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						disabled={
							isReturn &&
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.source_ownership
						}
						searchable
						data-cy='select-ownership'
					/>
				)}{' '}
				{isReturn && (
					<Select
						label={t('wms:TargetOwnership')}
						placeholder={t('wms:TargetOwnership')}
						value={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.target_ownership
								? selectedReport.filters.target_ownership
								: ownership
						}
						options={ownershipList.map(eu => ({ value: eu.id, label: eu.label }))}
						onChange={value => {
							setOwnership(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						disabled={
							inspectionType &&
							!inspectionType.is_modified_product &&
							selectedReport &&
							selectedReport.filters &&
							selectedReport.filters.target_ownership
						}
						searchable
						data-cy='select-ownership'
					/>
				)}
				{isArrival && (
					<Select
						label={materialStr}
						placeholder={materialStr}
						value={material}
						options={materials && materials.map(eu => ({ value: eu.id, label: eu.formatted }))}
						onChange={value => {
							selectMaterial(value)
							setMaterial(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-material'
					/>
				)}
			</FlexView>
			{inspectionType && inspectionType.is_modified_product && (
				<>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<Select
							label={t('wms:SourceMaterial')}
							placeholder={t('wms:SourceMaterial')}
							value={sourceMaterial}
							options={
								sourceMatList &&
								sourceMatList.map(mat => ({
									value: mat.id,
									label: mat.formatted
								}))
							}
							onChange={value => {
								selectMaterial(value)
								setSourceMaterial(value)
							}}
							width='100%'
							margin='8px 0 0 0'
							searchable
							data-cy='select-source-material'
						/>
					</FlexView>
					<FlexView width='100%' margin='0' padding='0' position='relative'>
						<Select
							label={t('wms:TargetMaterial')}
							placeholder={t('wms:TargetMaterial')}
							value={targetMaterial}
							options={
								targetMatList &&
								targetMatList.map(mat => ({
									value: mat.id,
									label: mat.formatted
								}))
							}
							onChange={value => setTargetMaterial(value)}
							width='100%'
							margin='8px 0 0 0'
							searchable
							data-cy='select-target-material'
						/>
					</FlexView>
				</>
			)}{' '}
			{isMatRequisition && (
				<FlexView width='100%' margin='0' padding='0' position='relative'>
					<Select
						label={materialStr}
						placeholder={materialStr}
						value={material}
						options={
							materials && materials.length && materials.map(eu => ({ value: eu.id, label: eu.formatted }))
						}
						onChange={value => {
							selectLevel(null)
							setRack(null)
							selectMaterial(value)
							setMaterial(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-material'
					/>
					<Select
						label={t('wms:SourceRack')}
						placeholder={t('wms:SelectRack')}
						value={rack}
						options={shelfList && shelfList.map(eu => ({ value: eu.id, label: eu.fullname }))}
						onChange={value => {
							selectLevel(value)
							setRack(value)
						}}
						width='100%'
						margin='8px 0 0 0'
						searchable
						data-cy='select-rack'
					/>
				</FlexView>
			)}{' '}
			{isArrival && sourceType === 'CUSTOMER_ITEM' && (
				<FlexView width='100%' margin='0 0 16px 0' padding='0' flexDirection='column'>
					<Select
						label={t(`wms:CustomerOrder`)}
						placeholder={t(`wms:CustomerOrder`)}
						value={cOrder}
						options={customerOrderList.map(c => ({
							label: c.label,
							value: c.label
						}))}
						onChange={value => {
							setCOrder(value)
						}}
						width='100%'
						inline={false}
						margin='0 8px 0 0'
						searchable
						disabled={checkRules()}
						data-cy='select-customer-order'
					/>
					<Select
						label={t(`wms:CustomerItem`)}
						placeholder={t(`wms:CustomerItem`)}
						value={cItem}
						options={
							filteredPoItems &&
							filteredPoItems.map(p => ({
								label: p.label,
								value: p.label
							}))
						}
						onChange={value => {
							setCItem(value)
						}}
						width='100%'
						inline={false}
						margin='0'
						disabled={checkRules()}
						searchable
						data-cy='select-customer-item'
					/>
				</FlexView>
			)}
			{isArrival && sourceType === 'SALES_ITEM' && (
				<FlexView width='100%' margin='0 0 16px 0' padding='0' position='relative' flexDirection='column'>
					<Select
						label={t(`wms:SalesOrder`)}
						placeholder={t(`wms:SalesOrder`)}
						value={sOrder}
						options={salesOrderList.map(value => ({
							label: value.label,
							value: value.label
						}))}
						onChange={value => {
							setSOrder(value)
						}}
						width='100%'
						inline={false}
						margin='0 8px 0 0'
						disabled={checkRules()}
						searchable
					/>
					<Select
						label={t(`wms:SalesItem`)}
						placeholder={t(`wms:SalesItem`)}
						value={sItem}
						options={
							filteredSalesItems &&
							filteredSalesItems.map(s => ({
								label: s.label,
								value: s.label
							}))
						}
						onChange={value => {
							setSItem(value)
						}}
						width='100%'
						inline={false}
						disabled={checkRules()}
						margin='0'
					/>
				</FlexView>
			)}
			{inspectionType && !isRigReturn() && (
				<FlexView width='100%' margin='0' padding='0' position='relative'>
					<LoadingOverlay visible={loadingQty} />
					{inspectionType.is_modified_product && (
						<Input
							label={t('wms:ExpectedQuantity')}
							placeholder={`${qntAvailable}`}
							margin='8px 0 0 0'
							min='1'
							max={'99999'}
							type='number'
							value={quantity}
							onChange={e => {
								setQuantity(toInteger(e.target.value))
							}}
							fontSize='small'
							width='100%'
							disabled={targetMaterial === null}
						/>
					)}
					{!inspectionType.is_modified_product && (
						<Input
							label={`${t('wms:Quantity')} ${`[${t('wms:AvailablePipes')}: ${qntAvailable}]`}`}
							placeholder={`${qntAvailable}`}
							margin='8px 0 0 0'
							min='1'
							max={qntAvailable}
							type='number'
							value={quantity}
							onChange={e => {
								setQuantity(toInteger(e.target.value))
							}}
							success={quantity < qntAvailable}
							error={quantity > qntAvailable}
							fontSize='small'
							width='100%'
							disabled={qntAvailable === 0}
						/>
					)}
				</FlexView>
			)}
			{inspectionType && checkButtonRules() && (
				<FlexView width='100%' margin='auto 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0'
						color='white'
						backgroundColor='secondary'
						disabled={isAddPartDisabled()}
						onClick={() => {
							addContent()
						}}>
						{t('wms:AddPart')}
						<Icon name='add' width='12px' height='10px' color='white' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			)}
			{inspectionType && !checkButtonRules() && (
				<FlexView width='100%' margin='auto 0 0 0' alignItems='center'>
					<Button
						fontSize='medium'
						margin='0'
						color='white'
						backgroundColor='warning'
						disabled={allNull()}
						onClick={() => resetFields()}
						data-cy='button-reset'>
						{t('wms:Restart')}
						<Icon name='refresh' color='white' height='16px' width='16px' margin='0 0 0 8px' />
					</Button>
				</FlexView>
			)}
		</FlexView>
	)
}

export default Filters
