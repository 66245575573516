/** @format */

import axios from 'axios'
import { STOCK_STATUS } from 'apps/wms/utils/enum'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

const newInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api/v2/wms`,
	headers: {
		'Content-Type': 'application/json',
		'vlr-authorization': `${btoa(JSON.stringify({ id: process.env.REACT_APP_ID }))}`
	}
})

export default {
	getRecInspList: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/recinsp/${wid}/list`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.recinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getMaterialsByEU: (wid, euId, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`material/${wid}/enduser/${euId}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getCOList: (wid, euId, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/polist/${wid}/${euId}/${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.polist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getSOList: (wid, euId, mid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`content/saleslist/${wid}/${euId}/${mid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200) {
						resolve(response.data.saleslist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getAvailableQnt: (
		wid,
		ownership,
		euId,
		mid,
		order,
		item,
		level,
		isSales,
		isCustomers,
		isAll,
		isModifiedProductInspection,
		token
	) =>
		new Promise((resolve, reject) => {
			const url = `content/${wid}/cnt-items-filtered`
			const listStatus = isModifiedProductInspection
				? `${STOCK_STATUS.code.Dispatched},${STOCK_STATUS.code.Delivered},${STOCK_STATUS.code.WIPO}`
				: `${STOCK_STATUS.code.InStock},${STOCK_STATUS.code.Ready},${STOCK_STATUS.code.ToBeRepaired},${STOCK_STATUS.code.ToBeScrapped},${STOCK_STATUS.code.UnderInspection}`
			instance
				.post(
					url,
					{
						ownership: ownership,
						enduser: euId,
						material: mid,
						level: level,
						customer_order: isCustomers && !isAll ? order : null,
						customer_item: isCustomers && !isAll ? item : null,
						sales_order: isSales && !isAll ? order : null,
						sales_items: isSales && !isAll ? item : null,
						status: listStatus
					},
					{
						headers: {
							Authorization: `${token}`
						}
					}
				)
				.then(response => {
					if (response.status === 200) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	newRI: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/recinsp/${wid}/list`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else if (response.status === 422) reject(new Error('wms.DispatchListMultipleMaterials'))
					else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateRi: (wid, stage, id, payload, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`/prepare/${wid}/stage/${stage}/list/${id}`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
		}),
	deleteRI: (wid, riid, token) =>
		new Promise((resolve, reject) => {
			instance
				.delete(`/recinsp/${wid}/list/${riid}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getRecInspReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/recinsp/${wid}/reports`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.recinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	getPerInspReports: (wid, token) =>
		new Promise((resolve, reject) => {
			instance
				.get(`/perinsp/${wid}/reports`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data.perinsplist)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	reopenRecInsp: (wid, payload, token) =>
		new Promise((resolve, reject) => {
			instance
				.post(`/recinsp/${wid}/release`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						resolve(response.data)
					} else {
						reject(new Error('CouldNotConnect'))
					}
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	deleteInspection: (wid, type, id, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.delete(`inspection/prepare/${wid}/type/${type}/list/${id}`, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	reopenInspection: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`inspection/execute/${wid}/releasecontentinspection`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response.data)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	uploadFile: (wid, params = {}, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`inspection/prepare/${wid}/saveFile`, params, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 201 || response.status === 204) resolve(response.data.filename)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	newInspection: (wid, payload, stage, anyOrders, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.post(`inspection/prepare/${wid}/stage/${stage}/list?anyOrders=${anyOrders}`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	updateInspection: (wid, payload, stage, inspID, anyOrders, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.put(`inspection/prepare/${wid}/stage/${stage}/list/${inspID}?anyOrders=${anyOrders}`, payload, {
					headers: {
						Authorization: `${token}`
					}
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		}),
	viewFile: (wid, type, reportId, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/type/${type}/downloadfile/${reportId}`, {
					headers: {
						Authorization: token
					},
					responseType: 'blob'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) resolve(response)
					else reject(new Error('CouldNotConnect'))
				})
				.catch(err => reject(err.response || err))
		}),
	getInspectorDefectPhoto: (wid, image, configType, token) =>
		new Promise((resolve, reject) => {
			newInstance
				.get(`inspection/search/${wid}/defect-photo`, {
					headers: {
						Authorization: token
					},
					params: { image, configType },
					responseType: 'arraybuffer'
				})
				.then(response => {
					if (response.status === 200 || response.status === 204) {
						const base64ImageString = Buffer.from(response.data, 'binary').toString('base64')
						resolve(`data:image/*;base64,${base64ImageString}`)
					} else reject(new Error('CouldNotConnect'))
				})
				.catch(err => {
					reject(err.response || err)
				})
		})
}
